<template>
    <v-container fluid grid-list-md>
        <v-layout justify-center xs12 sm8 md6>
            <v-carousel
                v-if="isMobile()"
                :cycle="cycle"
                height="auto"
                hide-delimiters
                light
            >
                <v-carousel-item
                    v-for="(instructionPic, index) in instructionPics"
                    :src="instructionPic.src"
                    :key="index"
                    style="height: auto"
                >
                    <div class="carousel-title">
                        {{ $t(instructionPic.title) }}
                    </div>
                </v-carousel-item>
            </v-carousel>
            <img src="@/assets/company_computer.png" v-else />
        </v-layout>
        <v-layout justify-center>
            <v-flex xs12 sm8 md6>
                <div v-for="(item, index) in introTextList" :key="index">
                    <v-subheader class="subheading">{{ item.title }}</v-subheader>
                    <v-card flat subheader>
                        <v-card-text :class="backColor">
                            <ul v-for="(text, index) in item.content" :key="index">
                                <li v-html="text"></li>
                            </ul>
                        </v-card-text>
                    </v-card>
                </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import store from '@/store'
import { SideBtnType } from '@/store'
import i18n from '@/i18n'
import { HOMETYPES } from '@/utils/utils'
// import Restaurants from '../components/Restaurants'

export default {
    name: 'Company',
    data () {
        return {
            backColor: '',
            // https://github.com/vuetifyjs/vuetify/issues/7196
            cycle: false,
            instructionPics: [
                { title: 'home.company-introduction', src: require('../assets/company_1.svg') },
                { title: 'home.company-production', src: require('../assets/company_2.svg') }
            ],
            introTextList: [{
                title: '公司介紹(Company)',
                content: ['建置智能置物櫃服務、智慧櫃體應用服務', '已完成遊樂園、動物園、學校、與台鐵、高捷、機場方面的置物櫃軟體服務推廣', '延伸櫃體串接服務，擴展各式應用', '長期預計完成3千到一萬的服務目標, 提供櫃體控制串接軟體平台服務, 需要網頁前端與後台的資訊人員', 'We provide turnkey service for smart locker systems, from zero to operations.', 'Our solution have made its presence in amusement parks, zoos, schools, train stations, and airports.', 'Daily operations, including remote control, are on the finger tip thanks to the locker control & connection service platform', 'Expansion is easy. New lockers can be linked physically and virtually, and are ready for additional value-added applications.']
            }, {
                title: '產品或服務',
                content: ['傳棧資訊股份有限公司是為智能置物櫃整合應用商，與萬洲通公司(<a href="https://autosale.com.tw/product-bbox/" rel="nofollow noopener noreferrer" target="_blank">https://autosale.com.tw/product-bbox</a>)的長期合作，完成郵局i郵箱 400台以上建置。',
                '自家產品，相關製作的網站為 <a href="https://bboxlocker.com" rel="nofollow noopener noreferrer" target="_blank">https://bboxlocker.com</a>， 透過此程式可使用操作置物櫃']
            }, {
                title: '使命',
                content: ['完成智能服務推展於無人自助設備(如：置物櫃、洗衣櫃-洗衣店24小時收衣幫手、自動販賣機)']
            }, {
                title: '員工福利',
                content: ['彈性工時：單日上班上限為8小時，每周最多48小時，每月總工時最多為160小時']
            }, {
                title: '上班地點',
                content: ['221 新北市大同路一段308號3樓']
            }]
        }
    },
    methods: {
        isMobile() {
            const home = this.$store.getters.pageHome
            if (home === HOMETYPES.STORE) {
                this.introTextList[1] = {
                    title: '產品或服務',
                    content: ['傳棧資訊股份有限公司是為智能置物櫃整合應用商，與萬洲通公司(<a href="https://autosale.com.tw/product-bbox/" rel="nofollow noopener noreferrer" target="_blank">https://autosale.com.tw/product-bbox</a>)的長期合作，完成郵局i郵箱 400台以上建置。',
                    '販賣櫃服務: 搭配網站 <a href="https://bboxlocker.com/storehome" rel="nofollow noopener noreferrer" target="_blank">https://bboxlocker.com/storehome</a> 購買櫃格銷售產品']
                }
            } else if (home === HOMETYPES.WASH){
                this.introTextList[1] = {
                    title: '產品或服務',
                    content: ['傳棧資訊股份有限公司是為智能置物櫃整合應用商，與萬洲通公司(<a href="https://autosale.com.tw/product-bbox/" rel="nofollow noopener noreferrer" target="_blank">https://autosale.com.tw/product-bbox</a>)的長期合作，完成郵局i郵箱 400台以上建置。',
                    '洗衣櫃服務: 搭配網站 <a href="https://bboxlocker.com/washhome" rel="nofollow noopener noreferrer" target="_blank">https://bboxlocker.com/washhome</a> 可操作櫃格放入送洗，並取回洗淨衣物']
                }
            } else {
                this.introTextList[1] = {
                    title: '產品或服務',
                    content: ['傳棧資訊股份有限公司是為智能置物櫃整合應用商，與萬洲通公司(<a href="https://autosale.com.tw/product-bbox/" rel="nofollow noopener noreferrer" target="_blank">https://autosale.com.tw/product-bbox</a>)的長期合作，完成郵局i郵箱 400台以上建置。',
                    '置物櫃服務: 搭配網站 <a href="https://bboxlocker.com" rel="nofollow noopener noreferrer" target="_blank">https://bboxlocker.com</a>， 透過此程式可使用操作置物櫃']
                }
            }
            //store.commit('setSnackBar', {message: navigator.userAgent, color: 'error'})
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    activated() {
        setTimeout(()=>{
            this.cycle = true;
        },50)
        store.commit('setTitle', i18n.t('about.items.software-company'))
        store.commit('setSideBtnType', SideBtnType.Back)
    },
    deactivated(){
        this.cycle = false
    }
}
</script>

<style scoped>
    .carousel-title {
        position: absolute;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        font-size:  18px;
        font-weight: bold;
        color: white;
        padding: 5px;
        width: 100%;
        text-align: center;
    }
</style>